import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Header from '../../shared-components/header/Header';
import { notificationsAtom } from '../../../atoms/Atoms';
import api from '../../../api/api';
import { H1 } from '../../shared-components/typography/Title';
import Subtitle from '../../shared-components/typography/Subtitle';
import Form from '../../shared-components/form/Form';
import Input from '../../shared-components/form/Input';
import Button from '../../shared-components/buttons/Button';


function Reset() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  useEffect(() => {
    if (params) {
      api
        .post('auth/reset/validation', {
          Id: params.id,
          Token: params.token,
        })
        .then((response) => {
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description: response.data.error,
                error: true,
              },
            ]);
             navigate('/');
          } else if (!response.data.data) {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description: response.data.message,
                error: true,
              },
            ]);
            navigate('/');
          }
        })
        .catch((error) => {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: `${t('password_update_success')} - ${error}`,
              error: true,
            },
          ]);
        });
    }
  }, [params]);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(`${t('required_field')}`),
      passwordConfirmation: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          `${t('same_password')}`
        )
        .required(`${t('required_field')}`),
    }),
    onSubmit: (values, { setSubmitting }) => {
      api
        .post(
          'auth/reset',
          {
            Id: params.id,
            Token: params.token,
            Password: values.password,
          },
          null
        )
        .then((response) => {
          console.log(response);
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description: response.data.error,
                error: true,
              },
            ]);
          } else if (response.data.internalMessage && response.data.success) {
            setNotifications([
              ...notifications,
              {
                title: `${t('yay')}`,
                description: `${t('password_update_success')}`,
                error: false,
              },
            ]);
            navigate('/');
          }
          formik.resetForm();
          setSubmitting(false);
        })
        .catch((error) => {
          formik.resetForm();
          setSubmitting(false);
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: `${t('password_update_success')} - ${error}`,
              error: false,
            },
          ]);
        });
    },
  });

  return (
    <div>
      <div className="flex flex-col justify-center py-6 px-4 md:px-0">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <H1 text={t('password_reset')} />
          <div className="w-full">
            <Form>
              <Input
                label={t('password')}
                required
                id="password"
                name="password"
                type="password"
                autoComplete="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                placeholder="***"
                className="col-span-2"
                error={
                  formik.errors.password &&
                  formik.touched.password &&
                  formik.errors.password
                }
              />
              <Input
                label={t('confirm_password')}
                required
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                autoComplete="password"
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                placeholder="***"
                className="col-span-2"
                error={
                  formik.errors.passwordConfirmation &&
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
              />
            </Form>
            <div className="flex flex-row">
            <Button
              text={t('cancel')}
              textColor="text-gray-200"
              onClick={(e) => {
                e.preventDefault();
                navigate('/');
              }}
              className="bg-red-500 hover:bg-red-600 w-20 mr-2"
            />
            <Button
              text={t('recover')}
              textColor="text-gray-200"
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
              onClick={formik.submitForm}
              loaderColor="bg-white"
              className="bg-yellow-500 hover:bg-yellow-600 w-20"
            />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reset;
