// Navbar.js
import React, { useState } from 'react';
import { useMount } from 'react-use';
import './shared-components/navbar.css';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import WarningBanner from '../warning-banner/WarningBanner';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import bookingApi from '../../../api/bookingApi';

const navbarOptionsStyles = {
  color: 'white',
  margin: 10,
  textDecoration: 'none',
  fontSize: '1rem',
  transition: 'color 0.7s, background-color 0.7s', // Add transition for smooth effect
};

const activeLinkStyles = {
  color: 'rgb(250, 179, 11)',
  backgroundColor: 'transparent', // Set a transparent background for active links
  borderBottom: '2px solid rgb(250, 179, 11)', // Add an underline for active links
  transition: 'color 0.7s, background-color 0.7s, border-bottom 0.7s', // Add transitions for a smoother effect
};


const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { t, i18n } = useTranslation();
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const [endDate, setEndDate] = useState(null);
  const [hasEvents, setHasEvents] = useState(null);
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)



  function searchTimeSlots() {
    bookingApi
      .post('events/search/days/grouped', {
        searchTerm: null,
        type: null,
        hostId: null,
        locationId: null,
      })
        .then((response) => {
          if(response.data.data.length > 0){
           setHasEvents(true);
          }
        })
        .catch((error) => {       
          console.error(error);
        });
  }

  useMount(() => {
    searchTimeSlots();  
  });

  return (
    <div>
      <div className="navbar-full">
        <div className="navbar-container">
          <a className="navbar-option" style={{
              ...(window.location.pathname === '/' ? activeLinkStyles : {}),
            }} href="/">
            Home
          </a>
          <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/subscriptions' ? activeLinkStyles : {}),
            }} href="/subscriptions">
            Subscriptions
          </a>
          <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/dropin' ? activeLinkStyles : {}),
            }} href="/dropin">
            Drop in
          </a>
          {hasEvents && <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/events' ? activeLinkStyles : {}),
            }} href="/events">
            Events
          </a>}
          <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/gallery' ? activeLinkStyles : {}),
            }} href="/gallery">
            Gallery
          </a>
          {/* <a style={{
              ...navbarOptionsStyles,
              ...(window.location.pathname === '/ourphilosophy' ? activeLinkStyles : {}),
            }} href="/ourphilosophy">
            Our Philosophy
          </a> */}
        </div>       
      </div>
      <div className="navbar-dd">
        <div onClick={toggleDropdown}>
          <div className="navbar-title flex flex-row">
            <span>Menu </span>
            <ChevronDownIcon className="h-6 w-6 ml-2" aria-hidden="true" />
          </div>
        </div>
        {showDropdown && (
          <div>
            <div className="nav-dropdown">
              <a className="navbar-option" href="/">Home</a>
              <a className="navbar-option" href="/subscriptions">Subscriptions</a>
              <a className="navbar-option" href="/dropin">Drop in</a>
              {hasEvents &&  <a className="navbar-option" href="/events">Events</a> }
              <a className="navbar-option" href="/gallery">Gallery</a>
              {/* <a class="navbar-option" href="/ourphilosophy">Our Philosophy</a> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;