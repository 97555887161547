import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import {
  toLocalTime
} from '../../../../functions';
import {
  selectedRentalTimeSlot
} from '../../../../atoms/Atoms';
import '../shared-components/events.css';

function TimeSlotCardLanding({ timeSlot }) {
  const { t } = useTranslation();
  const setSelectedTimeSlot = useSetRecoilState(selectedRentalTimeSlot);

  return (
    <button
      type="button"
      key={timeSlot?.id}
      disabled={timeSlot?.isFull}
      onClick={() => setSelectedTimeSlot(timeSlot)}
      className={`${'text-accent'} md:mt-4 w-full truncate disabled:cursor-not-allowed text-left text-xs font-medium line-clamp-1 hover:text-accent outline-none focus:outline-none transition duration-500 ease-in-out`}
    >
      <div className="timeslot-style mb-2 rounded-xl pt-4 text-white text-md mx-4">
        <div className="text-md text-lg ml-2 mx-10 font-bold">{`${dayjs(timeSlot?.startTime).format('dddd, MMMM DD, YYYY')}`}</div>
        <div className="text-md ml-2 text-lg font-normal">{`${toLocalTime(timeSlot?.startTime).format('h:mm A')}-${toLocalTime(timeSlot?.endTime).format('h:mm A')}`}</div>
        <div className="text-md ">
        <div
          className={`${timeSlot?.isFull ? 'text-red-600 border-xl border-yellow-500 border-2 rounded-b-xl bg-white' : 'bg-white border-2 border-yellow-500 rounded-b-xl'} p-2 rounded-b-xl text-md font-medium tracking-tight text-left flex items-center space-x-2 w-full mt-0 leading-none hover:text-accent transition duration-500 easy-in-out truncate line-clamp-1`}
        >
        <div className="flex flex-col text-center font-semibold text-md text-red-500 mt-2">
          <span>{`${timeSlot?.isFull ? t('full_event') : ''}`}</span>
          <span className={`${timeSlot?.isFull ? t('hidden') : 'text-gray-600 py-2 text-xs md:text-md'}`}>{t('reserve_a_spot')}</span>
          <span className={`${timeSlot?.isFull ? t('hidden') : 'text-gray-600 text-xs md:text-md'}`}>{`${timeSlot?.maxCapacity-timeSlot?.currentCapacity} spot(s) available`}</span>
        </div>
        </div>
        </div>
      </div>
    </button>
  );
}

export default TimeSlotCardLanding;
