// Modal.js
import React from 'react';
import './modal.css';

const Modal = ({ isOpen, closeModal, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay"/>
  );
};

export default Modal;
