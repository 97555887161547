import React, { useState } from 'react';
import './shared-resources/search.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBox = ({ onSearch }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    onSearch(searchText);
  };

  const handleSearch = () => {
    onSearch(searchText);
  };

  return (
    <div className={`search-box ${expanded ? 'expanded' : ''}`}>
      <input
        type="text"
        placeholder={`${t('search')} ...`}
        className="search-input"
        onFocus={toggleExpand}
        onChange={handleInputChange}
        // onClick={toggleExpand} // Expand when input is clicked
        value={searchText}
      />
      <div className="search-icon" onClick={handleSearch}>
        <FontAwesomeIcon  icon={faSearch}></FontAwesomeIcon>
      </div>
    </div>
  );
};

export default SearchBox;