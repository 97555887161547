import React from 'react';
import { useTranslation } from 'react-i18next';
import envConfig from '../../../envConfig';

function Privacy() {  
  const { t } = useTranslation();

  return (
    <div className="mx-auto mt-12 py-24 justify-center">
     <h3 className="font-heading font-extrabold text-3xl text-center text-yellow-600">{t('privacy_policy')}</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-32 md:mt-12 mx-6 md:mx-64 mb-12">
        <div className="space-y-5">
          <div>
            <h1 className="text-md font-semibold">{t('what_info_we_collect')}</h1>
            <p className="text-sm">{t('what_info_we_collect_text')}</p>
          </div>
          <div>
            <h1 className="text-md font-semibold">
              {t('what_do_we_use_info_for')}
            </h1>
            <p className="text-sm">{t('what_do_we_use_info_for_text_1')}</p>
            <ul className="my-2 list-disc list-inside text-sm">
              <li>{t('what_do_we_use_info_for_text_2')}</li>
              <li>{t('what_do_we_use_info_for_text_3')}</li>
              <li>{t('what_do_we_use_info_for_text_4')}</li>
              <li>{t('what_do_we_use_info_for_text_5')}</li>
            </ul>
            <p className="text-sm">{t('what_do_we_use_info_for_text_6')}</p>
          </div>
          <div>
            <h1 className="text-md font-semibold">{t('how_we_protect_info')}</h1>
            <p className="text-sm">{t('how_we_protect_info_text')}</p>
          </div>
          <div>
            <h1 className="text-md font-semibold">{t('do_we_use_cookies')}</h1>
            <p className="text-sm">{t('do_we_use_cookies_text')}</p>
          </div>
          <div>
            <h1 className="text-md font-semibold mt-4">{t('do_we_disclouse_info')}</h1>
            <p className="text-sm">{t('do_we_disclouse_info_text')}</p>
          </div>
        </div>
        <div className="space-y-5">
          <div>
            <h1 className="text-md font-semibold">{t('online_privacy_only')}</h1>
            <p>{t('online_privacy_only_text')}</p>
          </div>
          <div>
            <h1 className="text-md font-semibold">{t('your_consent')}</h1>
            <p className="text-sm">{t('your_consent_text')}</p>
          </div>
          <div>
            <h1 className="text-md font-semibold">{t('changes_to_our_policy')}</h1>
            <p className="text-sm">{t('changes_to_our_policy_text')}</p>
          </div>
          <div>
            <h1 className="text-md font-semibold">{t('contacting_us')}</h1>
            <p className="text-sm">{t('contacting_us_text')}</p>
            <div className="mt-2">
              <a className="font-bold text-primary hover:text-accent" rel="noreferrer" href={envConfig.ContactUsSite} target="_blank">
                <h2>{envConfig.ContactUsSite}</h2>
              </a>
              <ul>
                <li><h2>{envConfig.ContactUsAddress1}</h2></li>
                <li><h2>{envConfig.ContactUsAddress2}</h2></li>
                <li><h2>{envConfig.ContactUsAddress3}</h2></li>
              </ul>
              <a className="text-primary hover:text-accent" href={`mailto:${envConfig.ContactUsEmail}`}>
                {envConfig.ContactUsEmail}
              </a>
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
}

export default Privacy;
