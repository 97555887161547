import React from 'react';
import './shared-components/NotFoundPage.css'; // Create this CSS file for styling
import Crab from '../../../assets/images/backgrounds/notfoundcoffee.jpg';

function NotFound() {
    return  <div className="not-found-container">
    <img
      src={Crab} // Replace with your image path
      alt="Page Not Found"
      className="background-image"
    />
    <div className="overlay">
      <h1>404 - Page Not Found</h1>
      <h1>Sorry, the page you are looking for does not exist.</h1>
    </div>
  </div>

}

export default NotFound;