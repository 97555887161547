import Home from '../components/pages/home/Home'
import Faq from '../components/pages/faq/Faq'
import NotFound from '../components/pages/404/NotFound'
import Privacy from '../components/pages/privacy/Privacy'
import Register from '../components/pages/register/Register'
import KnowledgeTest from '../components/pages/home/KnowledgeTest'
import DropIn from '../components/pages/dropin/DropIn'
import Subscriptions from '../components/pages/subscriptions/Subscriptions'
import Gallery from '../components/pages/gallery/Gallery'
import OurPhilosophy from '../components/pages/ourphilosophy/OurPhilosophy'
import Login from '../components/pages/login/Login'
import RecoverPassword from '../components/pages/login/RecoverPassword'
import Profile from '../components/pages/profile/Profile'
import Attendance from '../components/pages/admin/Attendance'
import Reset from '../components/pages/reset/Reset'
import MySubscriptions from '../../src/components/pages/profile/shared-components/subscriptions/Subscriptions'
import Events from '../components/pages/events/Events'
import EventsLandingPage from '../components/pages/events/EventsLandingPage'
import ExpiredSubscriptions from '../components/pages/profile/shared-components/subscriptions/ExpiredSubscriptions'

const routes = [
    {
      id: 'faq',
      name: 'FAQ',
      path: '/faq',
      exact: true,
      protected: false,
      element:Faq,
    },
    {
      id: 'privacy',
      name: 'Privacy',
      path: '/privacy',
      exact: true,
      protected: false,
      element: Privacy,
    },
    {
      id: 'login',
      name: 'login',
      path: '/login',
      exact: true,
      protected: false,
      element: Login,
    },
    { 
      id: 'recoverpassword',
      name: 'recoverpassword',
      path: '/recoverpassword',
      exact: true,
      protected: false,
      element: RecoverPassword,
    },
    {
      id: 'register',
      name: 'Register',
      path: '/register',
      exact: true,
      protected: false,
      element: Register,
    },
    {
      id: 'knowledgetest',
      name: 'KnowledgeTest',
      path: '/knowledgetest',
      exact: true,
      protected: false,
      element: KnowledgeTest,
    },
    {
      id: 'dropin',
      name: 'dropin',
      path: '/dropin/:timeSlotId?',
      exact: false,
      protected: false,
      element: DropIn,
    },
    {
      id: 'reset',
      name: 'reset',
      path: '/reset/:id/:token',
      exact: true,
      protected: false,
      element: Reset,
    },
    {
      id: 'subscriptions',
      name: 'subscriptions',
      path: '/subscriptions',
      exact: true,
      protected: false,
      element: Subscriptions,
    },
    {
      id: 'home',
      name: 'home',
      path: '/',
      exact: true,
      protected: false,
      element: Home,
    },
    {
      id: 'gallery',
      name: 'gallery',
      path: '/gallery',
      exact: true,
      protected: false,
      element: Gallery,
    },
    {
      id: 'ourphilosophy',
      name: 'ourphilosophy',
      path: '/ourphilosophy',
      exact: true,
      protected: false,
      element: OurPhilosophy,
    },
    {
      id: 'profile',
      name: 'profile',
      path: '/profile/:section?',
      exact: true,
      protected: false,
      element: Profile,
    },
    {
      id: 'subscriptions',
      name: 'subscriptions',
      path: '/profile/section/subscriptions',
      exact: true,
      protected: false,
      element: MySubscriptions,
    },
    {
      id: 'attendance',
      name: 'attendance',
      path: '/admin/attendance',
      exact: true,
      protected: false,
      element: Attendance,
    },
    {
      id: 'events',
      name: 'events',
      path: '/events',
      exact: true,
      protected: false,
      element: Events,
    },
    {
      id: 'eventLanding',
      name: 'eventLanding',
      path: '/events/:eventId?',
      exact: true,
      protected: false,
      element: EventsLandingPage,
    },
    {
      id: 'eventLanding',
      name: 'eventLanding',
      path: '/event/:eventId?',
      exact: true,
      protected: false,
      element: EventsLandingPage,
    },
    {
      id: 'expiredSubscription',
      name: 'expiredSubscription',
      path: '/subscription/expired/:subscriptionId?',
      exact: true,
      protected: false,
      element: ExpiredSubscriptions,
    },
    /* Error route */
    {
      id: '404',
      name: 'Not Found',
      path: '*',
      exact: true,
      protected: false,
      element:NotFound,
    },
]; 

export default routes;