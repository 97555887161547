import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './css/overlay.css'

function OverlayMessage() {

   return (
          <div className="overlayBody">
            <div className="overlaytext">
              "It's all about what you can <span style={{ color: 'rgb(250, 179, 11)' }}>achieve</span> with your <span style={{ color: 'rgb(250, 179, 11)' }}>body"</span>
            </div>
          </div>
  );
}

export default OverlayMessage;
