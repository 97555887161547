import React, { useEffect, useRef, useState } from "react";
import _ from 'lodash';
import moment from 'moment';
import { userAtom } from "../../../../../atoms/Atoms";
import bookingApi from '../../../../../api/bookingApi';
import { useRecoilValue } from "recoil";
import { getRecurrence, getDateExtensionType } from '../../../../../functions';
import Button from "../../../../shared-components/buttons/Button";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { Spin } from 'antd';
import './shared-components/ExpiredSubscriptions.css'; // Import the CSS file
import SubscriptionRenewalDrawer from "../../../../shared-components/drawers/SubscriptionRenewalDrawer";

function ExpiredSubscriptions() {
  const user = useRecoilValue(userAtom);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const { subscriptionId } = useParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const ref = useRef();
  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };
    const [planSelected, setPlanSelected] = useState(null);
    const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
    const [daysPassed, setDaysPassed] = useState(null);

    useEffect(() => {
      if (selectedSubscription) {
        const expirationDate = moment(selectedSubscription.endDate);
        const currentDate = moment();
        const diffDays = expirationDate.diff(currentDate, 'days');
        setDaysPassed(Math.abs(diffDays));      }
    }, [selectedSubscription]);

  useEffect(() => {
    getUserSubscriptions();
  }, [user]);

  const getUserSubscriptions = () => {
    setLoading(true);
    bookingApi
      .get(`subscriptions/${user?.id}`)
      .then((response) => {
        setLoading(false);
        setUserSubscriptions(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  
  useEffect(() => {
    getUserSubscriptions();
  }, [user]);
  
  useEffect(() => {
    if (userSubscriptions?.length > 0) {
      const selected = userSubscriptions.find((sub) => sub.id === subscriptionId);
      setSelectedSubscription(selected);
      console.log(selected);
    }
  }, [userSubscriptions, subscriptionId]);
  
  
    const handleRenewal = (index) => {
      setSelectedButtonIndex(index);
      setPlanSelected(selectedSubscription);
      openDrawer();
    };


  return (
    <div className="expired-subscriptions-container">
      {loading && (
        <div className="loading-container">
          <Spin size="large" />
          <p className="loading-text">Loading...</p>
        </div>
      )}
      {!loading && selectedSubscription != null ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="subscription-details-container"
        >
          <div className="subscription-details">
            <h1>Your subscription expired</h1>
            <p className="description text-sm">{selectedSubscription.subscription?.nameEn}</p>
            <p className="description text-sm">{selectedSubscription.subscription?.descriptionEn}</p>
            <p className="description text-sm">Location: {selectedSubscription.subscription?.location?.nameEn}</p>
            {/* <p>Expired: {moment(selectedSubscription.endDate).add(-4, 'hour').format('MMM DD, YYYY hh:mm a')}</p> */}
              <p className="description text-sm">
                Ended: <span className="end-date">{moment(selectedSubscription.endDate).add(-4, 'hour').format('MMM DD, YYYY hh:mm a')}</span>
                  {daysPassed === 0 ? (
                    <span style={{ color: 'red' }}> (today)</span>
                  ) : (
                    <span style={{ color: 'red' }}> ({daysPassed} {daysPassed === 1 ? 'day' : 'days'} ago)</span>
                  )}
              </p>
            <div className="benefits-list">
              <p className="description text-sm">Benefit(s)</p>
                {selectedSubscription.subscription?.benefits && _.map(selectedSubscription.subscription?.benefits, (b, i) => (
                  <div key={i} className="benefit-item ml-2 text-orange-400 text-sm">
                    <span>{`${b?.nameEn} ( ${b.limit} ${getRecurrence(b.recurrence)})`}</span>
                  </div>
                ))}
            </div>
            <div>

            </div>
            {/* {(!selectedSubscription.allowRenewal || !selectedSubscription.subscription.isActive) && (
              <p className="renew-text">This subscription is not renewable.</p>
            )} */}
            <br />
            { selectedSubscription && selectedSubscription.subscription.isActive && (
              <Button
                text={t('renew')}
                textColor="text-white"
                disabled={loading}
                loading={loading}
                onClick={handleRenewal}
                loaderColor="bg-white"
                className="renew-btn bg-orange-600 hover:bg-orange-800"
              />
            )}
            <div>
              <p className="text-sm text-green-500 mt-4">If you renew now the new subscription will start today and end in {`${selectedSubscription.subscription.dateExtensionValue}`} {`${getDateExtensionType(selectedSubscription.subscription.dateExtensionType)}`}.</p>
            </div>
          </div>
        </motion.div>
      ) : null}
      <SubscriptionRenewalDrawer isOpen={isDrawerOpen} selectedSubscription={planSelected} closeDrawer={closeDrawer} />
    </div>
  );
}

export default ExpiredSubscriptions;
