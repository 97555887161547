/* eslint-disable no-confusing-arrow,react/jsx-curly-newline */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import envConfig from '../envConfig';

function ProtectedRoute({ component: Component, ...rest }) {
  const [user] = useLocalStorage(`${envConfig.StorageKey}-user`);

  return (
    <Routes>
        <Route
        {...rest}
        render={(props) =>
            user ? (
            <Route
                {...rest}
                render={(routeProps) => <Component {...routeProps} />}
            />
            ) : (
            <Navigate
                to={{
                pathname: '/',
                state: { from: props.location },
                }}
            />
            )
        }
        />
    </Routes>
  );
}

export default ProtectedRoute;
