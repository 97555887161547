import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LoginForm from './shared-components/LoginForm';
import { H1 } from '../../shared-components/typography/Title';
import { userAtom, isLoggingInAtom } from '../../../atoms/Atoms';
import './shared-components/login.css';


function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const isLoggingIn = useRecoilValue(isLoggingInAtom);

  useEffect(() => {
    if (user && !isLoggingIn) {
      navigate('/');
    }
  }, [user, isLoggingIn]);

  return (
    <div className="loginStyle">
      <div className="loginContent">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="loginHeader">{t('login')}</div>
          <div className="text-xs tracking-tight space-x-1">
            <span>{t('not_registered_yet')}</span>
            <Link
              to="/register"
              className="loginHighlightText transform duration-500 ease-in-out"
            >
              {t('register_here')}
            </Link>
          </div>
          <LoginForm />
          <div className="text-xs tracking-tight mt-4 space-x-1">
            <span>{t('forgot_password')}</span>
            <Link
              to="/recoverpassword"
              className="loginHighlightText transform duration-500 ease-in-out"
            >
              {t('recover_password')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
