import React, { useEffect } from 'react';
import { useSetRecoilState, useRecoilState } from 'recoil';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  cancellationDrawerOpenAtom,
  selectedBookingAtom,
} from '../../../../../../atoms/Atoms';
import { getEventBookingStatus } from '../../../../../../functions';

function Booking({ booking }) {
  const { t, i18n } = useTranslation();
  const [selectedBooking, setSelectedBooking] = useRecoilState(selectedBookingAtom);
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(cancellationDrawerOpenAtom);

  // useEffect(() => {
  //   console.log(selectedBooking);
  // }, [selectedBooking]);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCancelBooking = () => {
    openDrawer();
  };

  return (
    <button
      onClick={() => { setSelectedBooking(booking); handleCancelBooking(); }}
      type="button"
      className="relative w-full border rounded-lg ring-2 bg-gray-100 ring-offset-2 hover:border-opacity-30 hover:bg-gray-100 outline-none mb-4"
      style={{ position: 'relative' }}
    >
      <div className="flex w-full items-center sm:pt-4 md:p-4 space-x-2 flex-1 px-4 md:mb-2 overflow-auto">
        <div className="flex-col hidden">
          <img alt={booking.event.name} src={booking.event.imageUrl} className="h-16 w-auto rounded-lg" />
        </div>
        <div className="md:flex flex-col hidden mx-4">
          <div className="text-sm md:text-md text-black font-semibold">{booking.event.name} </div>
          {/* <div className="text-sm md:text-md text-black">{booking.event.description} </div> */}
          <div className={`${booking.status === 2 ? 'text-red-800 font-semibold' : 'text-black'} text-sm md:text-md`}>{t(getEventBookingStatus(booking.status))} </div>
        </div>
        <div className="hidden md:block flex-col text-black mx-2">         
          <span className="font-bold text-black">{t('time_slot')}</span>
          {booking.purchases.length > 0 && !booking.purchases[0].paidOn && (
            <div className="hidden">
              ${booking.purchases[0].amount - booking.purchases[0].amountPaid}
            </div>
          )}
          <div className="text-sm">
                {dayjs(booking.eventTimeSlot.startTime).add(-4, 'hour')
                  .format('dddd, MMMM D')}
          </div>
          <div className="text-sm">{`${dayjs(booking.eventTimeSlot.startTime).add(-4, 'hour').format('h:mm A')}-
          ${dayjs(booking.eventTimeSlot.endTime).add(-4, 'hour').format('h:mm A')}`}
          </div>         
        </div>
        {/* <div className="flex items-center">
        {checkTimeLeft() > 15 && booking.status !== 2 && (
          <button
            onClick={handleCancelBooking}
            type="button"
            className="px-6 py-2 bg-red-600 text-white rounded-md mx-4 hidden md:block"
          >
            Cancel
          </button>
        )}
      </div> */}
      </div>
      <div className="block p-2 md:hidden my-1 md:mb-0">
        {/* {booking.purchases.length > 0 && !booking.purchases[0].paidOn && (
          <div>
            ${booking.purchases[0].amount - booking.purchases[0].amountPaid}
          </div>
        )} */}
        <div className="flex-col text-sm md:text-md text-black ">
          <div className="font-semibold">{booking.event.name} </div>
          {/* <div>{booking.event.description} </div> */}
          <div>{t(getEventBookingStatus(booking.status))} </div>
        </div>
        <div className="text-sm md:text-md text-black">
            {dayjs(booking.eventTimeSlot.startTime).add(-4, 'hour')
                  .format('dddd, MMMM D')}
        </div>
        <div className="text-sm md:text-md text-black">{`${dayjs(booking.eventTimeSlot.startTime).add(-4, 'hour').format('h:mm A')}-
          ${dayjs(booking.eventTimeSlot.endTime).add(-4, 'hour').format('h:mm A')}`}
        </div>
        {/* {checkTimeLeft() > 15 && booking.status !== 2 && (
          <button
            onClick={handleCancelBooking}
            type="button"
            className="px-6 py-2 bg-red-600 text-white rounded-md my-4"
          >
            Cancel
          </button>
        )} */}
      </div>
    </button>
  );
}

export default Booking;
