import React from 'react';
import { Transition } from '@headlessui/react';
import envConfig from '../../../envConfig';
import { useTranslation } from 'react-i18next';
import '../../../components/shared-components/footer/shared-resources/footer.css';

function Footer() {
    const { t } = useTranslation();
  return (
    <Transition
      appear
      show
      enter="transform transition duration-700 ease-in-out"
      enterFrom="opacity-0 translate-y-full"
      enterTo="opacity-100 translate-y-0"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 -translate-x-0"
      leaveTo="opacity-0 -translate-x-full"
      className="footerStyle text-white h-20 flex-shrink-0 font-medium text-xs"
    >
      <div className="flex flex-col content-center">
        <div>{t('copyright')} © {new Date().getFullYear()} El Cubo PR</div> 
        <div className="flex flex-row p-2">
          <a href={`mailto:${envConfig.ContactEmail}`} className="footerLink hover:underline text-xs font-regular tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0 mr-2">{t('contact_us')}</a>
          <a href={envConfig.TosUrl} target="_blank" rel="noreferrer" className="footerLink hover:underline text-xs font-regular tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0">Terms of Use</a>
        </div>
      </div>
    </Transition>
  );
}

export default Footer;
