import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Tab } from '@headlessui/react';
import Panel from '../../../../shared-components/panel/Panel';
import { Spinner } from '../../../../shared-components/Spinner';
import bookingApi from '../../../../../api/bookingApi';
import {
  notificationsAtom,
  reloadBookingsAtom,
  selectedBookingAtom,
  loadingBookingsAtom,
  cancellationDrawerOpenAtom
} from '../../../../../atoms/Atoms';
// import NewCardModal from '../payments/shared-components/StripeModal';
import Booking from './shared-components/Booking';
import CancelBookingDrawer from '../../../../shared-components/drawers/CancelBookingDrawer';
import './shared-components/reservation.css';

function Reservations() {
  const { t } = useTranslation();
  const setLoadingBookings = useSetRecoilState(loadingBookingsAtom);
  const [selectedBooking, setSelectedBooking] = useRecoilState(selectedBookingAtom);
  const [reloadBookings, setReloadBookings] = useRecoilState(reloadBookingsAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loading, setLoading] = useState(false);
  const [hasUpcoming, setHasUpcoming] = useState(false);
  const [booking, setBooking] = useState(false);
  const [tabs, setTabs] = useState([   
    {
      key: 'upcoming',
      title: t('upcoming'),
      show: true,
      count: 0
    },
    {
      key: 'pendingpayment',
      title: t('pending_payment'),
      show: true,
      count: 0
    },
    {
      key: 'current',
      title: t('active'),
      show: true,
      count: 0
    },
    {
      key: 'past',
      title: t('past'),
      show: true,
      count: 0
    },
    {
      key: 'cancelled',
      title: t('cancelled'),
      show: true,
      count: 0
    }
  ]);
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(cancellationDrawerOpenAtom);

  function getBookings() {
    setLoading(true);
    setLoadingBookings(true);
    setBooking(null);
    bookingApi.get('events/grouped/bookings')
      .then((response) => {
        setLoading(false);
        setLoadingBookings(false);
        if (response.data.success) {
          setBooking(response.data.data);
          const temp = [...tabs];
          let allBookings = [];
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].key === 'past') {
              temp[i].show = response.data.data.past?.length > 0;
              temp[i].count = response.data.data.past?.length;
              if (temp[i].show) {
                allBookings = allBookings.concat(response.data.data.past);
              }
            } else if (temp[i].key === 'upcoming') {
              setHasUpcoming(response.data.data.upcoming?.length > 0);
              temp[i].show = response.data.data.upcoming?.length > 0;
              temp[i].count = response.data.data.upcoming?.length;
              if (temp[i].show) {
                allBookings = allBookings.concat(response.data.data.upcoming);
              }
            } else if (temp[i].key === 'current') {
              temp[i].show = response.data.data.current?.length > 0;
              temp[i].count = response.data.data.current?.length;
              if (temp[i].show) {
                allBookings = allBookings.concat(response.data.data.current);
              }
            } else if (temp[i].key === 'pendingpayment') {
              temp[i].show = response.data.data.pendingPayment?.length > 0;
              temp[i].count = response.data.data.pendingPayment?.length;
              if (temp[i].show) {
                allBookings = allBookings.concat(response.data.data.pendingPayment);
              }
            } else if (temp[i].key === 'cancelled') {
              temp[i].show = response.data.data.cancelled?.length > 0;
              temp[i].count = response.data.data.cancelled?.length;
              if (temp[i].show) {
                allBookings = allBookings.concat(response.data.data.cancelled);
              }
            }
          }
          if (selectedBooking) {
            const filtered = _.filter(allBookings, (b) => b.id === selectedBooking.id);
            if (filtered.length > 0) {
              setSelectedBooking(filtered[0]);
            }
          }
          setTabs(temp);
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingBookings(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  useEffect(() => {
    getBookings();
  }, []);

  useEffect(() => {
      getBookings();
  }, [reloadBookings]);

  return (
    <div className="flex flex-col justify-start flex-1 px-4 mb-4 overflow-y-auto space-y-4">
      <CancelBookingDrawer isOpen={isDrawerOpen} selectedBooking={selectedBooking} />

      <Panel>
        {!hasUpcoming && !loading && <div className="flex w-full m-4">You have no upcoming reservations</div>}
        <Tab.Group>
          {/* Header */}
          <Tab.List 
            defaultindex={0} 
            className="flex bg-blue-500 space-x-1 p-1 md:p-2 bg-opacity-20 rounded-xl text-md">
            {_.map(tabs, (e) => (
              <Tab
                disabled={!e.show}
                hidden={!loading && !e.count > 0}
                key={e.key}
                className={({ selected }) => {
                  if (selected) {
                    return 'bg-yellow-400 tracking-tight shadow text-white w-full py-2.5 px-1 ring-1 ring-offset-1 text-xs md:text-md md:leading-5 md:font-medium rounded-md outline-none focus:outline-none mx-1';
                  }
                  return `mx-1 text-black bg-white opacity-60 w-full py-2.5 text-xs md:text-sm tracking-tight md:leading-5 font-medium rounded-md ${e.show ? 'hover:bg-white hover:bg-opacity-50' : 'cursor-not-allowed'}`;
                }}
              >
                <div className={`text-md md:text-lg font-semibold ${loading ? 'tab-fade-in' : 'tab-fade-in active'}`}>{e.title}</div>
                <div className={`text-xs: md:text-md ${loading ? 'tab-fade-in' : 'tab-fade-in active'}`}>{!loading && e.count > 0 ? `(${e.count})` : ''}</div>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">            
            <Tab.Panel className="p-2">
              <Spinner spinning={loading} className="mx-auto" />
              {/* {booking?.upcoming?.length === 0 && !loading && <div>No upcoming bookings</div>} */}
              {_.map(booking?.upcoming, (b) => (
                <Booking booking={b} />
              ))}
            </Tab.Panel>
            {/* Sections */}
            <Tab.Panel className="p-2">
              <Spinner spinning={loading} className="mx-auto" />
              {_.map(booking?.pendingPayment, (b) => (
                <Booking booking={b} />
              ))}
            </Tab.Panel>
            <Tab.Panel className="p-2">
              <Spinner spinning={loading} className="mx-auto" />
              {_.map(booking?.current, (b) => (
                <Booking booking={b} />
              ))}
            </Tab.Panel>
            <Tab.Panel className="p-2">
              <Spinner spinning={loading} className="mx-auto" />
              {_.map(_.sortBy(booking?.past, 'startTime').reverse(), (b) => (
                <Booking booking={b} />
              ))}
            </Tab.Panel>
            <Tab.Panel className="p-2">
              <Spinner spinning={loading} className="mx-auto" />
              {_.map(booking?.cancelled, (b) => (
                <Booking booking={b} />
              ))}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Panel>
      {/* <NewCardModal /> */}
    </div>
  );
}

export default Reservations;
