// Logo.js
import React from 'react';
import logo from '../../../assets/images/logo/logo_no_background.png';
import './shared-components/logo.css';
import { NavLink } from 'react-router-dom';

const Logo = () => {
  return (
    <div className="logobody">
      <NavLink
        to="/">
           <img src={logo} className="logoimage" alt="Cube Logo" />
          <div className="logoprompt">
                <div>The Cube</div>
                <div>Functional Training</div>
          </div>
      </NavLink>     
    </div>

  );
}

export default Logo;
