import React, { useEffect, useRef, useState } from "react";
import _ from 'lodash';
import moment from 'moment';
import { reloadSubscriptionsAtom, userAtom } from "../../../../../atoms/Atoms";
import bookingApi from '../../../../../api/bookingApi';
import { useRecoilState, useRecoilValue } from "recoil";
import { getRecurrence } from '../../../../../functions';
import Button from "../../../../shared-components/buttons/Button";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import './shared-components/subscriptions.css';
import SubscriptionRenewalDrawer from "../../../../shared-components/drawers/SubscriptionRenewalDrawer";

function Subscriptions() {
    const user = useRecoilValue(userAtom);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [userSubscriptions, setUserSubscriptions] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [reload, setReload] = useRecoilState(reloadSubscriptionsAtom);

    const ref = useRef();
    const openDrawer = () => {
      setIsDrawerOpen(true);
    };
  
    const closeDrawer = () => {
      setIsDrawerOpen(false);
    };
      const [planSelected, setPlanSelected] = useState(null);
      const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
    // const buttonVariants = {
    //     initial: { y: 1000, opacity: 0 }, // Initial position (from the right) and invisible
    //     animate: { y: 0, opacity: 1 }, // Animate to the original position and visible
    //     exit: { y: 1000, opacity: 0 }
    //   };

    function getUserSubscriptions() {
      setUserSubscriptions([]);
      setLoading(true);
      bookingApi
        .get(`subscriptions/${user?.id}`)
        .then((response) => {
          setLoading(false);
          setUserSubscriptions(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
      }

      useEffect(() => {
        if (user) {
          getUserSubscriptions();
        }
      }, [user, reload]);

      // const handleRenewal = (index) => {
      //   setSelectedButtonIndex(index);
      //   setPlanSelected(selectedSubscription);
      //   openDrawer();
      // };

    return (
      <div key="main" className="overflow-auto">
        <div key="sub-header" className="text-lg md:text-xl font-extrabold tracking-tight text-white mt-4 ml-4">
            My Subscriptions
          </div>
        <div key="subs-subheader" className="ml-4 py-2 flex flex-row z-50">
          <div key="reload" className='mr-4 mt-2 text-sm'>
            {`${userSubscriptions && userSubscriptions.length > 0 ? `You have ${userSubscriptions.length} subscription(s)` : 'You have no subscriptions'}`}
          </div>
          <Button
            key="reload-btn"
            text={t('reload')}
            textColor="text-white"
            disabled={loading}
            loading={loading}
            onClick={() => {
              getUserSubscriptions();
            }}
            loaderColor="bg-white"
            className="bg-green-600 hover:bg-green-800 w-20 mr-2"
            />
        </div>
        <div key="sub-list">
        {userSubscriptions && _.map(userSubscriptions, (sub, index) => (
            <motion.div 
              key={index}
              id={index}
              className={`${!sub?.isActive ? "border-red-700" : "border-white"} mx-4 border rounded-md border-dashed p-4 w-auto mb-4`}
              initial={{
                x: 1000,
                opacity: 0,
                transition: {
                  duration: 0.3,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  duration: 0.3,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              }}
              exit={{
                x: 1000,
                opacity: 0,
                transition: {
                  duration: 0.3,
                  ease: [0.43, 0.13, 0.23, 0.96],
                },
              }}>
              <div className={`${sub.isActive ? "block" :"hidden" }`}>
              <div className="text-green-700 font-semibold">{sub.isActive ? 'Active' : 'Expired'}</div>
              <div className="font-semibold">{sub.subscription?.nameEn}</div>
              <div>Created: {moment(sub.createdAt).add(-4,'hour').format('MMM DD, YYYY h:mm a')}</div>
              <div>Description: {sub.subscription?.descriptionEn}</div>
              <div>Location: {sub.subscription?.location?.nameEn}</div> 
              {sub?.isActive && <div>Expires {moment().to(sub.endDate)}</div>}
              <div>Started: {moment(sub.startDate).add(-4,'hour').format('MMM DD, YYYY hh:mm a')}</div>
              <div>Ends: {moment(sub.endDate).add(-4,'hour').format('MMM DD, YYYY hh:mm a')}</div>               
              <div>Benefit(s)</div>
              {sub.subscription?.benefits && _.map(sub.subscription?.benefits, (b, i) => (
                <div key={i} className="mx-4">
                  <div>{`${b?.nameEn} ( ${b.limit} ${getRecurrence(b.recurrence)})`}</div>
                </div>         
              ))}
               {!sub.allowRenewal && 
                <div className="flex flex-col">
                    <p className="renew-text">This subscription is not renewable.</p>
                    <p className="renew-text">
                    You may choose from other available subscriptions <a className="text-blue-600" href="https://www.thecubepr.com/subscriptions">here</a>.
                    </p>
                </div>
                }
              </div>
              <div className={`${!sub.isActive ? "block" :"hidden" }`}>
              <div className="text-red-600 font-semibold">{sub.isActive ? 'Active' : 'Expired'}</div>
              <div className="font-semibold">{sub.subscription?.nameEn}</div>
              <div>Created: {moment(sub.createdAt).add(-4,'hour').format('MMM DD, YYYY h:mm a')}</div>
              <div>Description: {sub.subscription?.descriptionEn}</div>
              <div>Location: {sub.subscription?.location?.nameEn}</div> 
              <div>Started: {moment(sub.startDate).add(-4,'hour').format('MMM DD, YYYY hh:mm a')}</div>
              <div>Ended: {moment(sub.endDate).add(-4,'hour').format('MMM DD, YYYY hh:mm a')}</div>               
              <div>Benefit(s)</div>
              {sub.subscription?.benefits && _.map(sub.subscription?.benefits, (b, i) => (
                <div key={i} className="mx-4">
                  <div>{`${b?.nameEn} ( ${b.limit} ${getRecurrence(b.recurrence)})`}</div>
                </div>         
              ))}
              {sub.allowRenewal && sub.subscription.isActive && 
              <Button
                key="renew-btn"
                text={t('renew')}
                textColor="text-white"
                disabled={loading}
                loading={loading}
                onClick={() => {
                  setSelectedButtonIndex(index);
                  setPlanSelected(sub);
                  openDrawer();
                }}
                loaderColor="bg-white"
                className="renew-btn bg-orange-600 hover:bg-orange-800 w-20 mx-2 p-2 mt-2"
                />}
                {!sub.allowRenewal &&
                 <div className="flex flex-col">
                 <p className="renew-text">This subscription is not renewable.</p>
                 <p className="renew-text">You may choose from other available subscriptions.</p>
               </div>
                }   
                {sub.allowRenewal && !sub.subscription.isActive && 
                <p className="renew-text">This subscription is no longer available for renewal.</p>
                }             
              </div>
            </motion.div>
          ))}
          <SubscriptionRenewalDrawer isOpen={isDrawerOpen} selectedSubscription={planSelected} closeDrawer={closeDrawer} />
        </div>
      </div>
    )
}
export default Subscriptions