// Drawer.js
import React, { useEffect, useState } from 'react';
import './shared-components/Drawer.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useMount } from 'react-use';
import '../../../assets/css/custom.css';
import { notificationsAtom, reloadSubscriptionsAtom } from '../../../atoms/Atoms';
import bookingApi from '../../../api/bookingApi';
import { userAtom } from "../../../atoms/Atoms";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useRecoilState, useRecoilValue } from "recoil";
import envConfig from '../../../envConfig';
import {
  getRecurrence,
  getDateExtensionType,
  getRecurrenceLength,
  isSameDay,
  toLocalTime
} from "../../../functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';

const SubscriptionRenewalDrawer = ({ isOpen, selectedSubscription, closeDrawer }) => {
  const user = useRecoilValue(userAtom);
  const { t, i18n } = useTranslation();
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [benefits, setBenefits] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const price = selectedSubscription?.subscription.price || 0; // Make sure to handle null/undefined values
  const integerPart = Math.floor(price);
  const decimalPart = (price - integerPart).toFixed(2).slice(2); // Extract two decimal places

  const promoPrice = selectedSubscription?.subscription.promoPrice || 0; // Make sure to handle null/undefined values
  const integerPartPromo = Math.floor(promoPrice);
  const decimalPartPromo = (promoPrice - integerPartPromo).toFixed(2).slice(2); // Extract two decimal places
  const [reload, setReload] = useRecoilState(reloadSubscriptionsAtom);

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }
  
  function handleToggle() {
    setIsExpanded(!isExpanded);
  }

  function assignFreeSubscription() {
    setSubmitting(true);
    bookingApi
      .post(
        'subscriptions/user/subscription/renew',
      {
        userSubscriptionId: selectedSubscription.id,
        cardId: null ,
        payPalAgreementId: null,
        paypalReferenceNumber: '123',
        paypalInvoiceNumber: '123',
        userId: user.id
      },
      null
      )
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: response.data.error,
              error: true,
            },
          ]);
          setSubmitting(false);
        } else {
          setReload(!reload);
          setSubmitting(false);
          setNotifications([
            ...notifications,
            {
              title: `${t('alert')}`,
              description: response.data.message,
              error: false,
            },
          ]);
          closeDrawer();
        }
      })
      .catch((error) => {
        setSubmitting(false);
        console.error(error);
      });
  }

  function SubscriptionActivation(cardId,ppInvoiceNumber,ppReferenceNumber,ppAgreementId) {
    setSubmitting(true);
    bookingApi
      .post(
        'subscriptions/user/subscription/renew',
      {
        userSubscriptionId: selectedSubscription.id,
        cardId: null,
        payPalAgreementId: null,
        paypalReferenceNumber: ppReferenceNumber,
        paypalInvoiceNumber: ppInvoiceNumber,
        userId: user.id
      }
      )
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: response.data.error,
              error: true,
            },
          ]);
          setSubmitting(false);
        } else {
          setSubmitting(false);
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: response.data.message,
                error: false,
              },
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: t('subscribe_successfully'),
                error: false,
              },
            ]);
          }
          closeDrawer();
        }
      })
      .catch((error) => {
        setSubmitting(false);
        console.error(error);
      });
  }

  useMount(() => {
    setIsChecked(false);
  });

  return (
    <div className={`drawer ${isOpen ? 'open' : ''}`}>
      <button 
        className="close-button"
        onClick={ () => {
          closeDrawer();
          setIsChecked(false)
        }}>
        <div className="flex flex-row">
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          <div className="ml-2">Close</div>
        </div>
      </button>
      {user &&
      <div className="drawer-content">
        {/* Your drawer content goes here */}
        {!selectedSubscription?.isSubscribed && 
        <div>
        <div className="text-yellow-300">You are renewing</div>
        <div className="text-yellow-300 mb-2">the following subscription</div>       
        </div>}
        <div>{`${selectedSubscription && selectedSubscription?.subscription.nameEn}`}</div>
        <div className={`${selectedSubscription?.subscription.isOnPromo ? 'block' : 'hidden'}`}>{`${selectedSubscription && selectedSubscription?.descriptionEn}`}</div>
        {selectedSubscription && <div>
          <div className="accentText mt-0">@</div>
          <div>{selectedSubscription?.subscription.location.nameEn}</div>
        </div>}

        <div className={`${selectedSubscription?.subscription.isOnPromo ? 'block font-semibold mt-4' : 'hidden'}`}>{t('special_offer')}</div>
        {selectedSubscription?.subscription.isOnPromo && (
          <div>
            <FontAwesomeIcon className="mr-2" icon={faClock} />
            {isSameDay(toLocalTime(selectedSubscription?.subscription.promoEndDate), toLocalTime(dayjs()).startOf('date')) ? t('ends_today') : `${t('ends_on')} ${toLocalTime(selectedSubscription?.subscription.promoEndDate).format("MMM DD, YYYY")}`}
          </div>
        )}

        <div className={`${selectedSubscription?.subscription.isOnPromo && selectedSubscription?.subscription.promoPrice ? 'text-yellow-300 mt-4 text-2xl' : 'hidden'}`}>
          <div>
            <span className="text-white text-sm line-through ml-1"> ${integerPart}</span>
            <span className="text-white text-sm line-through">.{decimalPart}</span>
            {selectedSubscription?.subscription.recurrence > 0 && <span className="text-white text-sm line-through"> / {t(getRecurrenceLength(selectedSubscription?.subscription.recurrence))}</span>}
          </div>
          <span className="">${integerPartPromo}</span>
          <span className="text-sm">.{decimalPartPromo}</span>
          {selectedSubscription?.subscription.recurrence > 0 && <span className="text-yellow-300 text-2xl"> / {t(getRecurrenceLength(selectedSubscription?.subscription.recurrence))}</span>}
        </div>
        <div className={`${selectedSubscription?.subscription.isOnPromo && selectedSubscription?.subscription.promoPrice ? 'hidden' : 'text-yellow-300 mt-4 text-2xl'}`}>
          <span className="text-yellow-300">${integerPart}</span>
          <span className="text-sm">.{decimalPart}</span>
          {selectedSubscription?.subscription.recurrence > 0 && <span className="text-yellow-300 text-2xl"> / {t(getRecurrenceLength(selectedSubscription?.subscription.recurrence))}</span>}
        </div>

        {selectedSubscription?.subscription.isOnPromo && selectedSubscription?.subscription.recurrence > 0 && selectedSubscription?.subscriptiondateExtensionType > 0 && (
          <div className="font-bold">+{selectedSubscription?.subscription.dateExtensionValue} {t(getDateExtensionType(selectedSubscription?.subscription.dateExtensionType))} {t('free')}</div>
        )}

        <ul id="benefits" className="benefitsList">
          {selectedSubscription && <div className="accentText">Included Benefit(s)</div>}
          {selectedSubscription && _.map(selectedSubscription?.subscription.benefits, (b, bIndex) => (
          <li
            key={`${bIndex}`}
        >
           <div key={`div-${bIndex}`}>{`${b?.nameEn} (${b?.limit} ${getRecurrence(b.recurrence)})`}</div>
          </li>
          ))}
        </ul>
        { selectedSubscription?.subscription.isSubscribed && 
        <div className="mt-8 text-sm mb-6 border border-dashed rounded-lg p-4">You are already enrolled in this subscription. Please go to the <a className="text-yellow-500" href="/profile/subscriptions">my subscriptions</a> section in your profile to renew.</div>}
         {!selectedSubscription?.subscription.isSubscribed && 
         <div id="agrements" className="flex flex-col content-start agreementList">
          <div className="accentText">Terms</div>
          <div key="0">All sales are final</div>
          <div key="1">No refunds</div>
          <div key="2">Start of cycle (Date of purchase)</div>
          
          {selectedSubscription?.subscription.recurrence === 0 && (
            <div key="3" className="flex">
              <div className="mr-2">{t('duration')}:</div>
              <div className="lowercase space-x-1">
                {selectedSubscription?.subscription.dateExtensionValue} {t(getDateExtensionType(selectedSubscription?.subscription.dateExtensionType))}
              </div>
            </div>
          )}
          
          {/* <div key="2">Length of cycle (one month)</div> */}
          {/* <div key="3">Renews automatically</div> */}
          {/* <div key="4">Cancel anytime</div> */}
        </div>}
        {!selectedSubscription?.subscription.isSubscribed && 
        <div className="accentText flex items-center mx-1 mt-8 mb-4">
                <input
                  type="checkbox"
                  className="agreementCheckbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="agreement" className="acceptance">
                  I have read and accept the terms.
                </label>
              </div>}
      </div>}     
      {user && selectedSubscription?.subscription.price > 0 && !selectedSubscription?.subscription.isSubscribed && 
      <div className="mt-10 mx-4">
      {isOpen && (
        <PayPalScriptProvider options={{ 'client-id': envConfig.PayPalKey,'disable-funding': 'card', 'enable-funding': 'venmo'}}>
          <PayPalButtons
            disabled={!isChecked}
            createOrder={(data, actions) => actions.order.create({
              purchase_units: [
                  {
                      description: `${selectedSubscription?.subscription.isOnPromo ? `${selectedSubscription?.subscription.nameEn} ` : ''}${selectedSubscription?.subscription.descriptionEn} ${selectedSubscription?.subscription.location.nameEn} Starts: ${moment().add(-4, 'hour').format('MMM D YYYY')}`,
                      amount: {
                          value: selectedSubscription?.subscription.isOnPromo ? `${(selectedSubscription?.subscription.promoPrice).toFixed(2)}` : `${(selectedSubscription?.subscription.price).toFixed(2)}`,
                      },
                      custom_id: `${user?.id} ${selectedSubscription?.subscription.id}`
                  },
              ],
              application_context: { brand_name: 'The Cube PR', locale: `${i18n.language}-US`, shipping_preference: 'NO_SHIPPING' }
            })}
            onApprove={(data, actions) => actions.order.capture().then((details) => {
              // console.log('JSON Response', details);
              // console.log('JSON Response string', JSON.stringify(details));
              // console.log('selected Subscription id', selectedSubscription.id);
              // console.log('user id', user.id);
              const refNumber = details.id;
              const invoiceNumber =
                details.purchase_units[0]?.payments?.captures[0]?.id;
              SubscriptionActivation(null, invoiceNumber, refNumber, null);
            })}
          />
        </PayPalScriptProvider>
      )}
      </div>}
      {user  && !selectedSubscription?.isSubscribed  &&  selectedSubscription?.price > 0 && user.email === 'y@gopanza.com' && 
      <div>
        <button
          disabled={!isChecked} 
          className="athmovil-button"> ATH Movil
        </button>
      </div>}
      {user && selectedSubscription?.price === 0 && user.email === 'y@gopanza.com' && !selectedSubscription?.isSubscribed && 
      <div>
        <button
          disabled={!isChecked && submitting} 
          onClick={() => assignFreeSubscription()}
          className="accentButton mt-10 px-4 py-2"> Assign Subscription

        </button>
      </div>}
      {!user && 
      <div className="mt-24 px-6">Please <a className="text-yellow-500" href="/login">login</a> to purchase a subscription</div>}
    </div>
  );
};

export default SubscriptionRenewalDrawer;