
// LandingContent.js
import React from 'react';
import '../../../../assets/css/custom.css';
import { NavLink } from 'react-router-dom';


const DropIn = () => {
  return (
    <div>
      <NavLink
        to="/dropin">
          <button 
          className="primaryButton">
            Drop in
          </button>
      </NavLink>
    </div>
  );
}

export default DropIn;
