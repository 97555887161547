import axios from 'axios';
import envConfig from '../envConfig';

const api = axios.create({
  baseURL: envConfig.API_URL,
  headers: {
    'Ocp-Apim-Subscription-Key': envConfig.API_KEY,
    'x-api-utility-key': envConfig.REACT_APP_UTILITY_KEY,
    Authorization: `Bearer ${window.localStorage.getItem(`${envConfig.StorageKey}-token`)}`
  },
});
api.interceptors.request.use(
  (request) => {
    request.headers['Accept-Language'] = `${window.localStorage.getItem(`${envConfig.StorageKey}-lang`)}-US`.replaceAll('"', '');
    request.headers.Authorization = `Bearer ${window.localStorage.getItem(`${envConfig.StorageKey}-token`)}`.replaceAll('"', '');
    return request;
  }
);

export default api;
