import { React, useState } from 'react'
import _ from 'lodash'
import { Disclosure } from '@headlessui/react'
import { motion, AnimatePresence } from 'framer-motion'; // Import AnimatePresence and motion
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import SearchBox from '../../shared-components/search/Search'
import { useTranslation } from 'react-i18next'

const faqs = [
  {
    question: "¿Los intereses de la IRA son fijos?",
    answer:
      "Existen tres tipo de IRA. Las IRAs Roth son de interés fijo.",
    terms:
      "interes, intereses, fixed, interest",
  },
  {
    question: "¿Qué tipo de aportaciones o primas son deducibles?",
    answer:
      "La aportación o prima a una Cuenta IRA deberá hacerse en efectivo, cheque o giro para que la misma sea deducible.",
      terms: "taxes, impuestos",
  },
  {
    question: "¿Cómo se trata la aportación o prima para fines contributivos?",
    answer:
      "La aportación o prima total a una Cuenta IRA es deducible del ingreso bruto ajustado del individuo para fines de contribución sobre ingresos de Puerto Rico, dentro de los límites establecidos",
      terms: "taxes, impuestos",
  },
  {
    question: "¿Cómo se puede recibir el balance acumulado al retirarse?",
    answer:
      "El balance acumulado se puede recibir en una suma global o a plazos, por un período determinado.",
      terms: "balance, acumulado",
  },
  {
    question: "¿Cuál es la edad máxima hasta la cual se puede hacer una aportación?",
    answer:
      "Pueden hacerse aportaciones hasta el año contributivo en que el individuo sea menor de 75 años. Si al cierre del año contributivo el individuo tiene 75 años, no se permite la aportación a la Cuenta IRA para ese año contributivo.",
      terms: "",
  },
  // More questions...
]

export default function Example() {
  const { t } = useTranslation();
  const [filteredFaqs, setFilteredFaqs] = useState(faqs);
  const [searchResult, setSearchResult] = useState('');

  const CustomMinusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4"/>
    </svg>
  );
  
  const CustomPlusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
    </svg>
  );
  

  const handleSearch = (searchText) => {
    setSearchResult(searchText);
    if(searchText === '') 
    { 
      setFilteredFaqs(null);
      return 
    }
    setFilteredFaqs(_.filter(faqs, (item) => {
      // Check if the search text is found in either property1 or property2
      return _.includes((item.question).toUpperCase(), (searchText).toUpperCase()) 
            || _.includes((item.answer).toUpperCase(), (searchText).toUpperCase())
            || _.includes((item.terms).toUpperCase(), (searchText).toUpperCase());
    }));
    // Handle the search content here, e.g., perform a search
    // and set the search result in state   
  };
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">       
        <div className="mx-auto max-w-4xl">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">{t('faq_header')}</h2>
          <div className="m-4">
            <SearchBox onSearch={handleSearch} />
            {filteredFaqs && filteredFaqs.length > 0 && <p className={searchResult === '' ? 'hidden' : 'hidden'}>Results for: {searchResult}</p>}
          </div>
          <div className="hidden">{filteredFaqs && filteredFaqs.length}</div>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {filteredFaqs && filteredFaqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          <AnimatePresence key="faqquestions" wait>
                            {open ? (
                              <motion.div
                                key="minus"
                                initial={{ opacity: 0, rotate: -180 }}
                                animate={{ opacity: 1, rotate: 0 }}
                                exit={{ opacity: 0, rotate: -180 }}
                              >
                                <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                              </motion.div>
                            ) : (
                              <motion.div
                                key="plus"
                                initial={{ opacity: 0, rotate: 0 }}
                                animate={{ opacity: 1, rotate: -180 }}
                                exit={{ opacity: 0, rotate: 0 }}
                              >
                                <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      {/* Apply animation to the content */}
                      <AnimatePresence key="faqcontent" wait>
                        {open && (
                          <motion.div
                            key="content"                            
                            initial={{ opacity: 0, x: 50 }} // Initial position and opacity
                            animate={{ opacity: 1, x: 0 }} // Animation to visible position
                            exit={{ opacity: 0, x: 50 }} // Exit animation
                            transition={{ duration: 0.5 }}
                          >
                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Disclosure.Panel>
                  </>
                )}         
              </Disclosure>
            ))}           
          </dl>
          {filteredFaqs && filteredFaqs.length === 0 && <div>No results found. Try searching for key words instead.</div>}
        </div>
      </div>
    </div>
  )
}
