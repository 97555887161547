
// LandingContent.js
import React from 'react';
import '../../../../assets/css/custom.css';
import { NavLink } from 'react-router-dom';


const DropIn = () => {
  return (
    <NavLink
      to="/subscription">
        <button 
          className="primaryButton">
            Subscription
        </button>
  </NavLink>
  );
}

export default DropIn;
