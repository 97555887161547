import React from 'react';
import _ from 'lodash';

function Select({
  id,
  name,
  value,
  onChange,
  options,
  label,
  required,
  className,
  disabled,
}) {
  return (
    <div className={`${className} text-xs tracking-tight space-y-1`}>
      {label && (
        <div className="font-semibold">
          {label} {required && <span className="text-red-500">*</span>}
        </div>
      )}
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder="Seleccionar género..."
        className="disabled:opacity-50 placeholder-gray-300 text-gray-900 text-sm rounded-md p-3 w-full appearance-none focus:ring-1 focus:ring-accent border border-gray-200 focus:border-accent outline-none focus:outline-none transition ease-in-out duration-500"
      >
        {_.map(options, (option) => (
          <option
            key={option.key}
            value={option.value}
            disabled={option.disabled}
          >
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
