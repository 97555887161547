import React, { useState, useEffect} from 'react';
import './shared-resources/warning-banner.css';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../atoms/Atoms';
import { useTranslation } from 'react-i18next';
import bookingApi from '../../../api/bookingApi';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

function WarningBanner() {
    const user = useRecoilValue(userAtom);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [userSubscriptions, setUserSubscriptions] = useState([]);
    const navigate = useNavigate(); 
    const moment = require('moment');
    const currentDate = moment();

  function getUserSubscriptions() {
    setUserSubscriptions([]);
    setLoading(true);
    bookingApi
      .get(`subscriptions/${user?.id}`)
      .then((response) => {
        setLoading(false);     
        //console.log(_.sortBy(response.data.data,  (s) => !s.isActive));
        //setUserSubscriptions(_.sortBy(response.data.data,  (s) => s.isActive));
        // console.log(userSubscriptions);
        setUserSubscriptions(_.filter(response.data.data,  (s) => s.isActive));
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    }

    useEffect(() => {
      if (user && userSubscriptions.length === 0) {
        getUserSubscriptions();
      }
    }, [user]);

    //Expired with no renewal option
    // if (user && !user && userSubscriptions.length > 0 && !userSubscriptions[0].isActive && !userSubscriptions[0].canRenew) {
    //     //&& userSubscriptions[1].endDate < new Date() && userSubscriptions[0].endDate > new Date(new Date().setDate(new Date().getDate() - 14))
    //     return (
    //         <button 
    //             hidden={window.location.pathname !== '/'}
    //             type='button'
    //             className="warningHeader bg-red-600 text-white p-2 font-xs font-light text-center w-full"
    //             onClick={ () => navigate(`/subscriptions`)}>
    //             <div>
    //                 <div>Your subscription expired.</div>
    //                 <div>Choose from other available subscriptions to continue your journey.</div>
    //                 <div>Click here to view the options.</div>
    //             </div>
    //         </button>
    //     );
    // }

     //Active subscription expring in next X days
     if (user && userSubscriptions && userSubscriptions.length > 0 && userSubscriptions[0].isActive && moment(userSubscriptions[0].endDate).diff(currentDate, 'days') < 7){
        return (
            <button 
                hidden={window.location.pathname !== '/'}
                type='button'
                className="warningHeader bg-orange-600 text-white p-2 font-xs font-light text-center w-full"
                onClick={ () => navigate(`/subscriptions`)}>
                <div>
                    <div>Your subscription is set to expire {moment(userSubscriptions[0].endDate).diff(currentDate, 'days')} days.</div>
                    <div>You can renew your subscription once it has expired.</div> 
                    <p className="renew-text">
                      You may choose from other available subscriptions <a className="text-blue-400" href="https://www.thecubepr.com/subscriptions">here</a>.
                    </p>
                </div>
            </button>
        );
    }

     //Active and renewable subscription expring today
    //  if (user && userSubscriptions && userSubscriptions.length > 0 && userSubscriptions[0].canRenew && userSubscriptions[0].isActive && moment(userSubscriptions[0].endDate).diff(currentDate, 'days') === 1){
    //     return (
    //         <button 
    //             hidden={window.location.pathname !== '/'}
    //             type='button'
    //             className="warningHeader bg-purple-600 text-white p-2 font-xs font-light text-center w-full"
    //             onClick={ () => navigate(`/subscriptions`)}>
    //             <div>
    //                 <div>Your subscription is set to expire today!</div>
    //                 <div>You can renew your subscription once it has expired.</div> 
    //                 <div>Click here to renew.</div> 
    //             </div>
    //         </button>
    //     );
    // }

    //Active and non-renewable subscription expring today
    if (user && userSubscriptions && userSubscriptions.length > 0 && userSubscriptions[0].isActive && moment(userSubscriptions[0].endDate).diff(currentDate, 'days') === 1){
        return (
            <button 
                hidden={window.location.pathname !== '/'}
                type='button'
                className="warningHeader bg-purple-600 text-white p-2 font-xs font-light text-center w-full"
                onClick={ () => navigate(`/subscriptions`)}>
                <div>
                    <div>Your subscription is set to expire today!</div>
                    <div>You can renew your subscription once it has expired.</div> 
                    <div>Click here to view existing options.</div> 
                </div>
            </button>
        );
    }

        // //Test Scenario
        //  if (user && userSubscriptions && userSubscriptions.length > 0){
        //     return (
        //         <button 
        //             hidden={window.location.pathname !== '/'}
        //             type='button'
        //             className="warningHeader bg-red-600 text-white p-2 font-xs font-light text-center w-full"
        //             onClick={ () => navigate(`/subscription/expired/${userSubscriptions[0].id}`)}>
        //             <div>
        //                 <div>Your subscription has expired!</div>
        //                 <div>You can renew your subscription once it has expired.</div> 
        //                 <div>Click here to renew.</div> 
        //             </div>
        //         </button>
        //     );
        // }

  return null;
}

export default WarningBanner;
