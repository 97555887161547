import { React } from 'react';

function OurPhilosophy() {

    return(
      <div>
        INFO HERE
      </div>
    )
}
export default OurPhilosophy;