
// LandingContent.js
import React from 'react';
import logo from '../../../../assets/images/logo/logo_no_background.png';
import '../shared-components/css/landingMessage.css'
import { AnimatePresence, motion } from 'framer-motion';

const LandingMessage = () => {

  const containerVariants = {
    hidden: { opacity: 0, x: 1000 },
    visible: { opacity: 1, x: 0, transition: { delay: 2 } },
  };

  return (
    <AnimatePresence key="landingmessage">
    <motion.div
      className="landingContent"
      variants={containerVariants}
      initial="visible"
      animate="visible"
    >
      <div>
        <img className="landingImage" src={logo} />
      </div>
      <div >
        <div className="landingHeaderText">Joel Centeno</div>       
        <div className="landingSubheaderText">Head Trainer</div>
      </div>
    </motion.div>
    </AnimatePresence>
  );
}

export default LandingMessage;
