import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import api from '../../../../../api/api';
import {
  notificationsAtom,
  tokenAtom,
  userAtom,
} from '../../../../../atoms/Atoms';
import Form from '../../../../shared-components/form/Form';
import Input from '../../../../shared-components/form/Input';
import CleaveInput from '../../../../shared-components/form/CleaveInput';
import Select from '../../../../shared-components/form/Select';
import Button from '../../../../shared-components/buttons/Button';
import { H3 } from '../../../../shared-components/typography/Title';
import Subtitle from '../../../../shared-components/typography/Subtitle';
import Description from '../../../../shared-components/typography/Description';
import Panel from '../../../../shared-components/panel/Panel';
import envConfig from '../../../../../envConfig';
import styles from '../css/profile.module.css';

function Settings() {
  const { i18n, t } = useTranslation();
  const [localUser] = useLocalStorage(`${envConfig.StorageKey}-user`);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const token = useRecoilValue(tokenAtom);

  const formik = useFormik({
    initialValues: {
      fname: localUser ? localUser.firstName : '',
      lname: localUser ? localUser.lastName : '',
      email: localUser ? localUser.email : '',
      phone: localUser ? localUser.phoneNumber : '',
      gender: localUser ? localUser.gender : '',
      language: localUser ? localUser.preferredLanguage : '',
      allowSMS: localUser ? localUser.allowSMS : false,
      allowEmails: localUser ? localUser.allowEmails : false,
      allowPush: localUser ? localUser.allowPush : false,
    },
    validationSchema: Yup.object().shape({
      fname: Yup.string().required('Este campo es requerido.'),
      lname: Yup.string().required('Este campo es requerido.'),
      email: Yup.string()
        .email('Favor de ingresar un email valido.')
        .required('Este campo es requerido.'),
      phone: Yup.string().required('Este campo es requerido.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      const json = {
        Email: values.email,
        FirstName: values.fname,
        LastName: values.lname,
        PhoneNumber: values.phone,
        Password: values.password,
        Birthdate: new Date(),
        Gender: parseInt(values.gender, 10),
        PreferredLanguage: parseInt(values.language, 10),
        allowSMS: values.allowSMS,
        AllowEmails: values.allowEmails,
        AllowPush: true,
      };
      api
        .post('users/me', json)
        .then((response) => {
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description:
                `${t('error_profile_update')}`,
                error: true,
              },
            ]);
          } else {
            const profile = JSON.parse(localStorage.getItem(`${envConfig.StorageKey}-user`));
            profile.firstName = response.data.data.firstName;
            profile.lastName = response.data.data.lastName;
            profile.email = response.data.data.email;
            profile.phoneNumber = response.data.data.phoneNumber;
            profile.gender = response.data.data.gender;
            profile.preferredLanguage = response.data.data.preferredLanguage;
            profile.allowSMS = response.data.data.allowSMS;
            profile.allowEmails = response.data.data.allowEmails;
            profile.allowPush = true;
            profile.completeName = `${response.data.data.firstName} ${response.data.data.lastName}`;
            localStorage.setItem(`${envConfig.StorageKey}-user`, JSON.stringify(profile));
            setUser(profile);
            setNotifications([
              ...notifications,
              {
                title: `${t('profile_updated')}`,
                description:
                  `${t('success_profile_updated')}`,
                error: false,
              },
            ]);
          }
          setSubmitting(false);
        })
        .catch((error) => {
          setNotifications({ ...notifications, signUpError: error.message });
          setNotifications([
            ...notifications,
            {
              title: `${t('error_profile_update')}`,
              description: error.message,
              error: true,
            },
          ]);
          setSubmitting(false);
        });
    },
  });

  const handleChange = async (e) => {
    e.preventDefault();

    if (e.target.files.length) {
      const data = new FormData();
      data.append('imageFile', e.target.files[0]);
      await api
        .post('users/me/avatar', data)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
          } else {
            setUser(response.data.data);
            window.localStorage.setItem(
              `${envConfig.StorageKey}-user`,
              JSON.stringify(response.data.data)
            );
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div>
      <Panel>
        <H3 text={t('profile_picture')} />
        <Subtitle
          text={t('profile_picture_description')}
        />
        <div className="flex h-14 w-14 relative my-4">
          <label htmlFor="upload-button">
            <img
              src={user && user.avatar}
              alt={user && user.completeName}
              className="inline-block h-14 w-14 rounded-full cursor-pointer bg-gray-200"
            />
            {'  '}
            <div className="cursor-pointer flex items-center justify-center rounded-full h-7 w-7 absolute -right-1 -bottom-2 bg-accent">
              <FontAwesomeIcon icon={faPencil} className="text-xs text-white" />
            </div>
          </label>
          <input
            type="file"
            id="upload-button"
            className="hidden"
            accept="image/png, image/jpeg"
            onChange={handleChange}
          />
        </div>
        <Description text={t('profile_picture_instructions')} />
      </Panel>
      <br/>
      <Panel>
        <H3 text={t('edit_profile')} />
        <Subtitle
          text={t('edit_profile_description')}
        />
        <Form>
          <Input
            label={t('name')}
            required
            type="text"
            name="fname"
            id="fname"
            placeholder={t('sample_first_name')}
            autoComplete="given-name"
            autoCapitalize="true"
            value={formik.values.fname}
            onChange={formik.handleChange}
            error={
              formik.errors.fname && formik.touched.fname && formik.errors.fname
            }
          />
          <Input
            label={t('last_name')}
            required
            type="text"
            name="lname"
            id="lname"
            autoComplete="family-name"
            placeholder={t('sample_last_name')}
            autoCapitalize="true"
            value={formik.values.lname}
            onChange={formik.handleChange}
            error={
              formik.errors.lname && formik.touched.lname && formik.errors.lname
            }
          />
          <Input
            label={t('email')}
            required
            type="email"
            name="email"
            id="email"
            placeholder="juan@gmail.com"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={
              formik.errors.email && formik.touched.email && formik.errors.email
            }
          />
          <CleaveInput
            label={t('Phone')}
            required
            id="phone"
            name="phone"
            type="text"
            autoComplete="tel"
            options={{
              phone: true,
              phoneRegionCode: 'PR',
            }}
            value={formik.values.phone}
            onChange={(e) => {
              formik.setFieldValue('phone', e.target.rawValue);
            }}
            placeholder="787 777 7777"
            error={
              formik.errors.phone && formik.touched.phone && formik.errors.phone
            }
          />
          <Select
            label={t('gender')}
            id="gender"
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
            options={[
              {
                key: '5',
                value: 5,
                text: `${t('select_gender')}`,
                disabled: true,
              },
              {
                key: '0',
                value: 0,
                text: `${t('male')}`,
              },
              {
                key: '1',
                value: 1,
                text: `${t('female')}`,
              },
              {
                key: '2',
                value: 2,
                text: `${t('unknown_gender')}`,
              },
            ]}
          />
          <Select
            label={t('language')}
            id="language"
            name="language"
            value={formik.values.language}
            onChange={formik.handleChange}
            options={[
              {
                key: '0',
                value: 0,
                text: `${t('english')}`,
              },
              {
                key: '1',
                value: 1,
                text: `${t('spanish')}`,
              },
            ]}
          />
          <div>
            <div className="text-md md:text-xl  font-extrabold tracking-tight">{t('communications')}</div>
            <div className="text-xs md:text-sm">
              {t('communications_desc')}
            </div>
          </div>
          <div className="flex flex-row md:flex-col">
          <div className="flex items-center">
            <input
              value={formik.values.allowSMS}
              checked={formik.values.allowSMS}
              name="allowSMS"
              type="checkbox"
              onChange={formik.handleChange}
              className="w-6 h-6 text-accent mt-0 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="marketing-checkbox" className="ml-4 text-base font-medium text-acceptTos">
              {t('text_message')}
            </label>
          </div>
          <div className="flex items-center ml-4 md:ml-0 md:mt-2">
            <input
              value={formik.values.allowEmails}
              checked={formik.values.allowEmails}
              name="allowEmails"
              type="checkbox"
              onChange={formik.handleChange}
              className="w-6 h-6 text-accent bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="marketing-checkbox" className="ml-4 text-base font-medium text-acceptTos">
              {t('email')}
            </label>
          </div>
          </div>
        </Form>
        <div className="flex flex-row">
        <Button
          text={t('cancel')}
          textColor="text-gray-200"
          onClick={(e) => {
            e.preventDefault();
            formik.setValues({
              fname: localUser ? localUser.firstName : '',
              lname: localUser ? localUser.lastName : '',
              email: localUser ? localUser.email : '',
              phone: localUser ? localUser.phoneNumber : '',
              gender: localUser ? localUser.gender : '',
              language: localUser ? localUser.preferredLanguage : '',
            });
          }}
          className="bg-red-500 hover:bg-red-600 w-20 mr-2"
        />
        <Button
          text={t('save')}
          textColor="text-gray-200"
          disabled={formik.isSubmitting}
          loading={formik.isSubmitting}
          onClick={formik.submitForm}
          loaderColor="bg-white"
          className="bg-yellow-500 hover:bg-yellow-600 w-20 mr-2"
        />
      </div>
      </Panel>

    </div>
  );
}

export default Settings;
