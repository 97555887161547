import React from 'react';

function Panel({ children, dark }) {
  return (
    <div className={dark && 'dark'}>
      <div className="p-4 text-white rounded-xl bg-black dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700">
        {children}
      </div>
    </div>
  );
}

export default Panel;
