import React from "react";
import { motion } from "framer-motion";
import Img1 from '../../../assets/images/photos/innercircle.jpg';
import Img2 from '../../../assets/images/photos/jumpbar.jpg';
import Img3 from '../../../assets/images/photos/jumpsquat.jpg';
import Img4 from '../../../assets/images/photos/kbchest.jpg';
import Img5 from '../../../assets/images/photos/kbs.jpg';
import Img6 from '../../../assets/images/photos/kbsquat.jpg';
import Img7 from '../../../assets/images/photos/meettheboss.jpg';
import Img8 from '../../../assets/images/photos/plankbar.jpg';
import Img9 from '../../../assets/images/photos/pushup.jpg';
import Img10 from '../../../assets/images/photos/sidebar.jpg';
import Img11 from '../../../assets/images/photos/sidelunge.jpg';
import Img12 from '../../../assets/images/photos/squatting.jpg';
import Img13 from '../../../assets/images/photos/flexing.jpg';
import Img14 from '../../../assets/images/photos/jumping.jpg';
import Img15 from '../../../assets/images/photos/smiling.jpg';
import "./shared-components/ImageGallery.css";
import Logo from "../../shared-components/logo/Logo";

const images = [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15
];

const transition = { duration: 3 };

const Gallery = () => {
  return (
    <div>
      <Logo />
      <div className="image-gallery-container">
        <h1 className="gallery-title my-4">Strength and Endurance</h1>
        <div className="image-gallery">
          {images.map((image, index) => (
            <motion.div
              key={index}
              className="image-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={transition}
            >
              <img src={image} alt={`Image ${index + 1}`} />
            </motion.div>
          ))}
       </div>
      </div>
    </div>
  );
};

export default Gallery;
