import React from 'react';
import Loader from '../loader/Loader';

function Button({
  disabled,
  onClick,
  className,
  loading,
  text,
  loaderColor,
  textColor,
}) {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`${className} h-10 flex justify-center items-center text-center disabled:opacity-25 text-xs transition duration-500 ease-in-out font-semibold rounded-md
      `}
    >
      {loading ? (
        <Loader color={loaderColor} />
      ) : (
        <span className={`${textColor} mx-2`}>{text}</span>
      )}
    </button>
  );
}

export default Button;
