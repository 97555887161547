import { React, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import '../home/shared-components/css/quiz.css'
import { getI18n, useTranslation } from 'react-i18next';
import { set } from 'lodash';

function KnowledgeTest() {
    const [ t ] = useTranslation();
    const questionsENG = [
        {
          question: "What can you invest in with your IRA?",
          options: [
            "Real estate",
            "Only government bonds",
            "Virtually any stock, bond, ETF, or mutual fund",
            "Only cash"
          ],
          correctAnswer: 2,
        },
        {
          question: "How much can you contribute to your IRA for the 2022 tax year?",
          options: [
            "$5,500 for both traditional and Roth IRAs",
            "$6,500 for both traditional and Roth IRAs",
            "$5,500 for each IRA account",
            "Unlimited contributions"
          ],
          correctAnswer: 1,
        },
        {
          question: "Why contribute to an IRA instead of a standard brokerage account?",
          options: [
            "IRAs provide guaranteed returns",
            "IRAs offer unlimited contributions",
            "IRAs have tax benefits and allow tax-deferred growth",
            "IRAs have no withdrawal restrictions"
          ],
          correctAnswer: 2,
        },
        {
          question: "Will you get a tax deduction for your traditional IRA contributions?",
          options: [
            "Yes, all contributions are tax-deductible",
            "No, contributions to traditional IRAs are never tax-deductible",
            "It depends on your income and retirement plan eligibility",
            "Only if you contribute more than $10,000"
          ],
          correctAnswer: 2,
        },
        {
          question: "What's the main difference between a traditional and Roth IRA?",
          options: [
            "Traditional IRAs are pre-tax, while Roth IRAs are post-tax",
            "Traditional IRAs have higher contribution limits",
            "Roth IRAs have mandatory minimum distributions",
            "Traditional IRAs can be opened by anyone"
          ],
          correctAnswer: 0,
        },
        {
          question: "What is the deadline for IRA contributions for a specific tax year?",
          options: [
            "December 31 of the tax year",
            "April 15 of the following year",
            "July 4 of the following year",
            "It varies based on your birthdate"
          ],
          correctAnswer: 1,
        },
        {
          question: "Can you borrow money from your IRA?",
          options: [
            "Yes, you can take out a loan from your IRA",
            "No, it's not possible to borrow from an IRA",
            "Only if you repay the amount within 60 days",
            "Yes, but it's subject to a high-interest rate"
          ],
          correctAnswer: 1,
        },
        {
          question: "Can you contribute to an IRA if you have a 401(k) at work?",
          options: [
            "No, it's not allowed",
            "Yes, but only to a traditional IRA",
            "Yes, you can contribute to both a traditional and Roth IRA",
            "Only if your 401(k) is with the same financial institution"
          ],
          correctAnswer: 2,
        },
        {
          question: "Under what circumstances could you use your IRA before retirement without penalty?",
          options: [
            "Anytime you want, with no penalties",
            "To buy a second home",
            "To pay for higher education expenses",
            "Only if you become a professional athlete"
          ],
          correctAnswer: 2,
        },
        {
          question: "What if you retire before age 59 1/2 and want to access your IRA?",
          options: [
            "You can never access your IRA before age 59 1/2",
            "You can only access your original contributions",
            "You can make early withdrawals with IRS-defined life expectancy payments",
            "You can only access your IRA with a court order"
          ],
          correctAnswer: 2,
        },
      ];
    const questionsSPA = [
    {
        question: "¿En qué puedes invertir con tu IRA?",
        options: [
        "Bienes raíces",
        "Solo bonos gubernamentales",
        "Virtualmente cualquier acción, bono, ETF o fondo mutuo que desees",
        "Solo efectivo"
        ],
        correctAnswer: 2,
    },
    {
        question: "¿Cuánto puedes contribuir a tu IRA para el año fiscal 2017?",
        options: [
        "$5,500 para ambas IRA tradicionales y Roth",
        "$6,500 para ambas IRA tradicionales y Roth",
        "$5,500 para cada cuenta IRA",
        "Contribuciones ilimitadas"
        ],
        correctAnswer: 1,
    },
    {
        question: "¿Por qué contribuir a una IRA en lugar de una cuenta de corretaje estándar?",
        options: [
        "Las IRAs ofrecen retornos garantizados",
        "Las IRAs permiten contribuciones ilimitadas",
        "Las IRAs tienen beneficios fiscales y permiten el crecimiento con diferimiento de impuestos",
        "Las IRAs no tienen restricciones de retiro"
        ],
        correctAnswer: 2,
    },
    {
        question: "¿Obtendrás una deducción de impuestos por tus contribuciones a una IRA tradicional?",
        options: [
        "Sí, todas las contribuciones son deducibles de impuestos",
        "No, las contribuciones a las IRA tradicionales nunca son deducibles de impuestos",
        "Depende de tus ingresos y tu elegibilidad para un plan de jubilación",
        "Solo si contribuyes más de $10,000"
        ],
        correctAnswer: 2,
    },
    {
        question: "¿Cuál es la principal diferencia entre una IRA tradicional y una IRA Roth?",
        options: [
        "Las IRA tradicionales son cuentas previas a impuestos, mientras que las IRA Roth son cuentas posteriores a impuestos",
        "Las IRA tradicionales tienen límites de contribución más altos",
        "Las IRA Roth tienen distribuciones mínimas obligatorias",
        "Las IRA tradicionales pueden ser abiertas por cualquier persona"
        ],
        correctAnswer: 0,
    },
    {
        question: "¿Cuál es la fecha límite para las contribuciones a una IRA en un año fiscal específico?",
        options: [
        "31 de diciembre del año fiscal",
        "15 de abril del año siguiente",
        "4 de julio del año siguiente",
        "Varía según tu fecha de nacimiento"
        ],
        correctAnswer: 1,
    },
    {
        question: "¿Puedes pedir prestado dinero de tu IRA?",
        options: [
        "Sí, puedes obtener un préstamo de tu IRA",
        "No, no es posible pedir prestado de una IRA",
        "Solo si reembolsas el monto dentro de 60 días",
        "Sí, pero está sujeto a una tasa de interés alta"
        ],
        correctAnswer: 1,
    },
    {
        question: "¿Puedes contribuir a una IRA incluso si tienes un plan 401(k) en el trabajo?",
        options: [
        "No, no se permite",
        "Sí, pero solo a una IRA tradicional",
        "Sí, puedes contribuir tanto a una IRA tradicional como a una IRA Roth",
        "Solo si tu plan 401(k) es con la misma institución financiera"
        ],
        correctAnswer: 2,
    },
    {
        question: "¿En qué circunstancias podrías utilizar tu IRA antes de la jubilación sin penalización?",
        options: [
        "En cualquier momento que desees, sin penalizaciones",
        "Para comprar una segunda vivienda",
        "Para pagar gastos de educación superior",
        "Solo si te conviertes en atleta profesional"
        ],
        correctAnswer: 2,
    },
    {
        question: "¿Y si te jubilas antes de los 59 1/2 años?",
        options: [
        "Nunca podrás acceder a tu IRA antes de los 59 1/2 años",
        "Solo puedes acceder a tus contribuciones originales",
        "Puedes hacer retiros anticipados con pagos de expectativa de vida definidos por el IRS",
        "Solo puedes acceder a tu IRA con una orden judicial"
        ],
        correctAnswer: 2,
    },
    ];
    const [questions, setQuestions] = useState(getI18n().language === 'en' ? questionsENG : questionsSPA);
    const [answers, setAnswers] = useState(Array(questions.length).fill(null)); // Initialize an array to store answers
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [showResults, setShowResults] = useState(false);
    const score = (correctAnswers / questions.length) * 100;
    const [currentQuestion, setCurrentQuestion] = useState(0);  
    const [selectedAnswer, setSelectedAnswer] = useState(null);

    const handleAnswerSelect = (index) => {
        setSelectedAnswer(index);
        // Make a copy of the current answers array
        // const newAnswers = [...answers];
        // // Update the selected answer for the current question
        // newAnswers[currentQuestion] = selectedOptionIndex;
        // // Update the answers state
        // setAnswers(newAnswers);
        // Move to the next question
        //setCurrentQuestion(currentQuestion + 1);
        if(questions[currentQuestion].correctAnswer === index) { setCorrectAnswers(correctAnswers + 1)}
    };

    const handleNextQuestion = () => {
        if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
        setSelectedAnswer(null);
        }
    };   

    const renderOptions = () => {
    return questions[currentQuestion].options.map((option, index) => (
        <motion.div
        key={index}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        >
        <button
            className={`option ${selectedAnswer === index ? "selected" : ""}`}
            onClick={() => handleAnswerSelect(index)}
        >
            {option}
        </button>
        </motion.div>
        ));
      };

    function setQuestionArray()
    {
        return getI18n().language === 'es' ? questionsSPA : questionsENG
    };

    useEffect (() => {
        setQuestions(setQuestionArray());
    }, [getI18n().language]);
      
    
  return (
    <Transition
      appear
      show
      enter="transform transition duration-700 ease-in-out"
      enterFrom="opacity-0 translate-y-full"
      enterTo="opacity-100 translate-y-0"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 -translate-y-0"
      leaveTo="opacity-0 -translate-y-full"
      className="text-white h-20 mb-4 flex justify-center items-center flex-shrink-0 font-medium text-xs px-4"
    >
       <div className="main-container">
       <div className="quiz-container">
        <AnimatePresence key="knowledgetest" initial={false} wait>
            <motion.div
                key={currentQuestion}
                initial={{ opacity: 0, y: 25 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -100 }}
                transition={{ duration: 1.0 }}
            >
            <h2 className="mb-4"
                hidden={showResults}>
                    {t('question')} {currentQuestion + 1} {t('of')} {questions.length}
            </h2>
            <p className="mb-4"
                hidden={showResults}>
                {questions[currentQuestion].question}
            </p>
            <div hidden={showResults}>{renderOptions()}</div>
            {questions && (currentQuestion + 1 !== questions.length) && 
             <motion.div
                whileTap={{ scale: 0.95 }}
                >
                <button
                className="next-button"
                onClick={handleNextQuestion}
                disabled={selectedAnswer === null}
            >
                {t('next_question')}
            </button>
            </motion.div>}
            {questions && (currentQuestion + 1 === questions.length) 
                && (answers[questions.length] !== null) && 
             <button
                className="showresults-button"
                hidden={showResults}
                onClick={() => setShowResults(true)}
                disabled={selectedAnswer === null}
            >
                t('show_results')
            </button>}
            {questions && showResults && (selectedAnswer !== null) && <div className="results-container">
                <h2 className="mb-4 text-xl">Quiz Results</h2>
                <p className="mb-4">You answered {correctAnswers} out of {questions.length} questions correctly.</p>
                <p className="mb-4">Your score: {score.toFixed(2)}%</p>
            </div>}
            </motion.div>
        </AnimatePresence>
        </div>
        </div>
    </Transition>
  );
}

export default KnowledgeTest
